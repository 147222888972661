.stepper-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    border: 2px solid #cdccd2;
    padding: 50px;
    border-radius: 10px;
  }

  .arrow {
    
    height: 50px;
    width: 50px;
    transform: rotate(45deg);
    background-color: white;
    position: absolute;
    right: 185px;
    top: -25px;
    border-top: 2px solid #cdccd2;
    border-left: 2px solid #cdccd2;
  }

  .arrow-transportar {
    
    height: 50px;
    width: 50px;
    transform: rotate(45deg);
    background-color: white;
    position: absolute;
    right: 365px;
    top: -25px;
    border-top: 2px solid #cdccd2;
    border-left: 2px solid #cdccd2;
  }

  .arrow-entregue {
    
    height: 50px;
    width: 50px;
    transform: rotate(45deg);
    background-color: white;
    position: absolute;
    right: 13px;
    top: -25px;
    border-top: 2px solid #cdccd2;
    border-left: 2px solid #cdccd2;
  }
  
  .step {
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  
  .step-circle {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #3dd269;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    z-index: 1;
  }
  
  /* Animação de pulse para bolinha */
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  
  .step-circle-current {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #56a6dd; /* Cor de fundo */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    z-index: 1;
  }
  
  .step-circle-current::after {
    content: "";
    position: absolute;
    top: -21%;
    left: -20%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #56a6dd; /* Cor do "pulse" */
    transform: translate(-50%, -50%);
    z-index: -1; /* Coloca o efeito atrás da bolinha */
    animation: pulse 1.5s infinite ease-in-out; /* Animação infinita */
  }
  
  
  .step-circle-wait {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #cac9ce;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    z-index: 1;
  }
  
  /* Animação da linha */
  @keyframes pulse-line {
    0% {
      transform: scaleX(1);
      opacity: 1;
    }
    50% {
      transform: scaleX(1.2);
      opacity: 0.1;
    }
    100% {
      transform: scaleX(1);
      opacity: 1;
    }
  }
  
  .step-line {
    position: absolute;
    width: 2px;
    height: 30px; /* Ajusta a altura da linha */
    background-color: #27d76f;
    top: 34px;
    left: 13px;
    z-index: 0;
    animation: pulse-line 3s infinite ease-in-out; /* Animação da linha */
    transform-origin: center; /* Faz a escala no centro */
  }
  
  /* Linha cinza */
  .step-line-gray {
    background-color: #cac9ce; /* Cor cinza */
  }

  .step-line-mobile {
    position: absolute;
    width: 2px;
    height: 30px; /* Ajusta a altura da linha */
    background-color: #27d76f;
    top: 34px;
    left: 13px;
    z-index: 0;
    animation: pulse-line 3s infinite ease-in-out; /* Animação da linha */
    transform-origin: center; /* Faz a escala no centro */
  }
  
  /* Linha cinza */
  .step-line-mobile-gray {
    background-color: #cac9ce; /* Cor cinza */
  }
  
  /* Estilo da descrição */
  .step-description {
    margin-left: 16px; /* Espaçamento entre bolinha e texto */
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    max-width: 650px; /* Limita a largura do texto */
    white-space: normal; /* Permite quebra de linha */
  }
  
  .step:not(:last-child) {
    margin-bottom: 48px; /* Espaçamento consistente entre bolinhas */
  }
  