@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.footer {
  background-color: #213572;
  color: white;
  /* text-align: center; */
  padding: 20px;
  /* position: fixed; */
  width: 100%;
  bottom: 0;
  left: 0;
}

.footer-container {
  max-width: 800px;
  margin: 0 auto;
  height: 100px;
}
