.container-body {
  width: 75%;
  margin: 0 auto;
  padding: 20px;
}
.container-undefined {
  min-height: 65vh;
}
.loading-body {
  width: 75%;
  margin: 0 auto;
  padding: 20px;
  height: 375px;
}
.Title {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container-infos-nota {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.Line-Title-Entregas {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #213572;
  width: 100%;
}

.Line-Title-Entregas p {
  font-size: 25px;
  color: white;
  font-weight: 500;
}

.container-endereco {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}
.container-endereco span {
  font-size: 16px;
}

.container-confirm-end {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-top: 20px;
}
.container-confirm-end p {
  font-size: 16px;
}
.container-radios {
  display: flex;
  gap: 15px;
}

.container-textarea {
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.infoEntrega p {
  line-height: 1.3;
}

.checkbox-envio {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  font-weight: bold;
  flex-direction: column;
  gap: 15px;
}

/*==================== STYLES MOBILE =================================*/

.container-body-mobile {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
}

.container-endereco-mobile {
  display: flex;
  flex-direction: column;
}

.container-endereco-mobile span {
  font-size: 16px;
}

.container-confirm-end-mobile {
  display: flex;
  flex-direction: column;
  /* margin-left: 40px; */
  margin-top: 20px;
}

.container-confirm-end-mobile p {
  font-size: 16px;
}
.Line-Title-Entregas-mobile {
  display: flex;
  align-items: center;
  background-color: #213572;
  width: 100%;
  text-align: center;
}

.Line-Title-Entregas-mobile p {
  font-size: 25px;
  color: white;
  font-weight: 500;
}

.container-undefined-mobile {
  min-height: 70vh;
}
