@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.HeaderLogo {
  width: 100%;
  height: 90px;
  background-color: #d11e22;
  display: flex;
  text-align: center;
}

.HeaderLogo img {
  margin-left: 90px;
}
.container-entrega {
  width: 100%;
  display: flex;
  background-color: #213572;
  height: 110px;
  color: white;
  font-weight: 300 !important;
  flex-direction: column;
  text-align: center;
}
.container-titles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.title {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-right: 90px;
}

.title p {
  font-size: 32px;
  color: white;
}
.subtitle {
  color: white;
  font-size: 16px;
  font-style: italic;
  margin-right: 90px;
}

/* ============================ STYLE MOBILE =====================================*/
.HeaderLogo-mobile {
  width: 100%;
  height: auto;
  background-color: #d11e22;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.HeaderLogo-mobile img {
  height: 70px;
}
.container-titles-mobile {
  display: flex;
  color: white;
  justify-content: space-around;
  align-items: center;
  /* margin-left: 20px; */
}

.subtitle-mobile {
  font-size: 12px;

  color: white;
}
.title-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
  margin-bottom: 10px;
  color: white;
  font-size: 20px;
  text-align: center;
}
.title-mobile p {
  font-size: 15px;
}
.HeaderLogo-mobile img {
  margin-left: 10px;
}
.container-mobile {
  width: 100%;
  display: flex;
  background-color: #213572;
  height: auto;
  color: white;
  flex-direction: column;
  text-align: center;
  /* font-size: 20px; */
}
